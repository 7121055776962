import React from "react"
import Row from "../components/bootstrap/row"
import SponsorBox from "../components/sponsor-box"
import NHSBSALogoImage from "./nhsbsa-logo-image"
import LakesideSoftwareLogoImage from "./lakeside-software-logo-image"
import NFONLogoImage from "./nfon-logo-image"
import ImgCoslaLogo from "./img-cosla-logo"
import ImgIrmsLogo from "./img-irms-logo"
import ImgHealthEducationEnglandLogo from "./img-health-education-england-logo"
// import ImgGMCALogo from "./img-gmca-logo"
import ImgICCANLogo from "./img-iccan-logo"
import ImgVersusArthritisLogo from "./img-versus-arthritis-logo"
import ImgTechUKLogo from "./img-tech-uk-logo"
// import ImgCCSLogo from "./img-ccs-logo"
import ImgGDSLogo from "./img-gds-logo"
import ImgDigitalBirminghamLogo from "./img-digital-birmingham-logo"
// import ImgNHSSBSLogo from "./img-nhs-sbs-logo"
import ImgNHSDigitalLogo from "./img-nhs-digital-logo"
import ImgCabinetOfficeLogo from "./img-cabinet-office-logo"
import ImgMODLogo from "./img-mod-logo"
// import ImgNHSImprovementLogo from "./img-nhs-improvement-logo"
import ImgBCSLogo from "./img-bcs-logo"
import ImgCipfaLogo from "./img-cipfa-logo"
import ImgCIPDLogo from "./img-cipd-logo"
import ImgPPMALogo from "./img-ppma-logo"
import ImgGPALogo from "./img-gpa-logo"
// import ImgSmarterWorkingLogo from "./img-smarter-working-logo"
import ImgIdeasUKLogo from "./img-ideas-uk-logo"
import ImgIronMountainLogo from "./img-iron-mountain-logo"
import ImgNHSPropertyServicesLogo from "./img-nhs-property-services-logo"
import ImgNSANDIGPSLogo from "./img-nsandi-gps-logo"

const Judges = () => {
  return (
    <div style={{
      marginBottom: `5rem`
    }}>
      <Row>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff"><ImgCabinetOfficeLogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff" padding=".5rem"><ImgTechUKLogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff" padding="1rem"><ImgMODLogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff" padding="1.5rem"><ImgNHSDigitalLogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff"><ImgCipfaLogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff" padding=".5rem"><ImgCIPDLogo /></SponsorBox><br />
        </div>
      </Row>
      <Row>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff"><ImgICCANLogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff"><NHSBSALogoImage /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff"><LakesideSoftwareLogoImage /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff" padding="1rem"><ImgCoslaLogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff" padding="2rem"><NFONLogoImage /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff" padding="1.7rem"><ImgIrmsLogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff" padding="1.8rem"><ImgBCSLogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff" padding=".5rem"><ImgPPMALogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff" padding="1rem"><ImgVersusArthritisLogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff"><ImgHealthEducationEnglandLogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff"><ImgDigitalBirminghamLogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff"><ImgIdeasUKLogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff" padding=".5rem"><ImgGDSLogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox><ImgIronMountainLogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox fontColor="#fff" bgColor="#fff" padding="1rem"><ImgGPALogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
        <SponsorBox fontColor="#000" bgColor="#fff" padding="1.5rem"><ImgNHSPropertyServicesLogo /></SponsorBox><br />
        </div>
        <div className="col-6 col-sm-4 col-lg-2">
          <SponsorBox><ImgNSANDIGPSLogo /></SponsorBox><br />
        </div>
      </Row>
    </div>
  )
}

export default Judges